import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
// import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <div style={{backgroundColor: '#5160B9'}}className='w-full h-full flex justify-center items-center'>
      <a className="text-white text-3xl" href="mailto:info@tractorbeam.com">info@tractorbeam.com</a>
    </div>
  </Layout>
)

export default IndexPage
